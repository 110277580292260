.cp-form-step-two {
    text-align: center;
    margin-top: 5em;
  }
  
  .react-calendar {
    display: inline-block;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 0px 8px #5D1A71;
    padding: 20px;
    margin-bottom: 2em;
    width: 100%;
  }

  .manual-date-inputs{
    display: flex;
    margin-bottom: 1em;
  }
  .manual-date-inputs>input{
    margin-right: 1em;
    margin-left: 0.2em;
  }

  @media (max-width: 768px) {
    .manual-date-inputs{
      display: block;
      margin-bottom: 1em;
    }
    .manual-date-inputs>input{
      margin-bottom: 1em;
    }
  }

  input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
  
  .react-calendar__tile {
    padding: 10px;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }

  .react-calendar__tile--active {
    background-image: repeating-linear-gradient(-45deg, #e5b5f3 0 4px, #f0e5f3 2px 7px) !important;;
    color: black !important;
    font-weight: bold!important;
  }

  .react-calendar__tile--now{
    background-color: #f0f0f0!important;
    cursor: not-allowed!important;
  }

  .react-calendar__navigation__label{
    background-color: #c0c8c0 !important;
  }

  .react-calendar__month-view__days__day:disabled {
    background-color: #f0f0f0;
    color: #333;
    cursor: not-allowed; 
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    visibility: hidden;
  }

  .react-calendar__navigation__label {
    pointer-events: none;
  }

  .react-calendar__month-view__days__day{
    background-color: #daf8da;
  }
  
  .error-message {
    color: red;
    font-size: 1rem;
    margin-bottom: 1em;
  }
  
  .grayed {
    background-image: none!important;
    background-color: #f0f0f0 !important; 
    color: #333!important;
    cursor: not-allowed!important; 
    font-weight: normal!important;
  }

  .selected-dates {
    font-size: 1.2rem;
    color: #333;
    margin-top: 1em;
  }
  
  .reserved {
    background-color: #5d1a71!important;
    color: #f0f0f0!important;
    cursor: not-allowed!important;
  }

  .reserved-days-legend{
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
    background-color: #5d1a71;
  }

  .grayed-days-legend{
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
    margin-left: 1em;
    background-color: #f0f0f0;
  }

  .avalaible-days-legend{
    width: 1em;
    height: 1em;
    margin-left: 1em;
    margin-right: 0.5em;
    background-color: #daf8da;
  }

  .calendar-legend{
    display: flex;
    align-items: baseline;
  }

  .calendar-container{
    margin-left: 10%;
    margin-right: 10%;
    text-align: left;
  }
  