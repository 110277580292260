.legend-structure {
    margin: 0;
    padding: 0.875em 1.5em;
    width: 100%;
    line-height: 1.125;
    text-transform: none;
    font-family: century-gothic, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #fefefe;
    border: 1px solid #d1d1d1;
    background-color: #28C9B6;
}

.fieldset-structure {
    margin-top: 1.625rem;
}

.fieldset-wrapper {
    padding: 0.125rem 1.5rem 1.25rem;
    border: 1px solid #d1d1d1;
    border-top: 0;
}

.logo-container {
    margin-bottom: 2rem;
}

.form-input {
    margin-bottom: 1em;
    margin-top: 1em;
}

.margin-right {
    margin-right: 20px;
}

.hr {
    clear: both;
    max-width: 75rem;
    height: 0;
    margin: 1.25rem auto;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #cacaca;
    border-left: 0;
}

.btn-submit {
    background-color: #28C9B6;
}

h1 {
    color: #28C9B6;
}

.autocomplete-siren {
    width: 50%;
}

.logo-titre {
    text-align: center;
}

.suggestion {
    padding: 6px;
    cursor: pointer;
    border: 1px;
    border-right: 1px solid #7ba89a;
    border-left: 1px solid #7ba89a;
    border-bottom: 1px solid #7ba89a;
}

.suggestion:hover {
    background-color: grey;
}

.search {
    z-index: 1;
    background-color: white;
    position: absolute;
}

.primary {
    background-color: #28C9B6 !important;
    border-color: #28C9B6 !important;
}

.siren-item {
    cursor: pointer;
}

.modal-text {
    text-align: center;
}

.custom-menu {
    background-color: gray;
}

.custom-menu-item {
    font-weight: bold;
    height: 4em;
}

.memori .dropdown-menu {
    background-color: #F1F4FA;
}

.memori .dropdown-item{
    font-weight: bold;
    border-bottom: solid 1px #ccc;
}

.modal-footer {
    display: flex;
    justify-content: center;
}

.modal-header {
    display: flex;
    justify-content: center;
}


.centred-title {
    text-align: center;
}

.alert-info-referent {
    display: block;
    border: none !important;
    background-color: #ecdff5 !important;
}

#RFS-StepButton {
    pointer-events: none;
}

.messageMembership,
.help-message-error {
    text-align: center;
}

.text-fin-form {
    text-align: justify;
}

label[for] {
    cursor: pointer;
}

.text-help-siren {
    padding: 0;
    list-style: none;
    font-weight: 400;
    font-size: 85%;
    color: #717171 !important;
}

.memori-section-title {
    font-size: x-large;
    margin-top: 1em;
}

.memori-form-container {
    padding-bottom: 1em;
    max-width: 100vw;
}

.memori-form-field {
    margin-top: 1em;
}

.memori-form-container .text-danger {
    margin-bottom: 0;
}