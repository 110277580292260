.loader-container{
  text-align: center;
  margin-top: 20%;
}

.cp-expos-form header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 4em;
  padding-right: 4em;
  height: 8em;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.cp-expos-form img{
  margin-left: 1em;
  width: 12em;
}

@media (max-width: 768px) {
  .cp-expos-form img{
    display: none;
  }
}


.previous-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background-color: #A23775;
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}

.cp-expos-form{
  margin-bottom: 3em;
}

.previous-button:hover {
  background-color: #5D1A71;
  transform: scale(1.05);
}

.previous-button:focus {
  outline: none;
}

.previous-button::before {
  content: '←'; /* Flèche gauche */
  font-size: 20px;
  color: white;
}

.previous-button:active {
  transform: scale(0.95);
}


.project-presentation {
  text-align: center;
  padding: 2em;
  border-radius: 10px;
}


.body-presentation {
  text-align: left;
  margin: 0 auto;
  padding: 2em;
  max-width: 80em;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}

.cp-expos-form h1 {
  text-align: center;
  color: #A23775;
  font-size: 1.5rem;
  margin-bottom: 1em;
}

.body-presentation h1{
  font-weight: bold;
}

.project-presentation h2 {
  text-align: center;
  color: #A23775;
  font-size: 2rem;
  margin-bottom: 1em;
}

.body-presentation h3 {
  color: #5D1A71;
  font-size: 1.8rem;
  margin-bottom: 0.5em;
}

.body-presentation>h4 {
  color: #333;
  font-size: 1.2rem;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  border-bottom: 2px solid #A23775;
  padding-bottom: 0.3em;
}

.body-presentation>p {
  font-size: 1rem;
  line-height: 1.2rem;
  color: #555;
  margin-bottom: 1.5em;
}

.body-presentation>ul {
  padding-left: 1.5em;
  margin-bottom: 2em;
}

.body-presentation>ul li {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 0.5em;
  color: #666;
}

.btn-change-step {
  padding: 12px 20px;
  margin-top: 1em;
  font-size: 16px;
  color: #fff;
  background-color: #A23775;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-change-step:hover {
  background-color: #5D1A71 ;
}

.btn-change-step:disabled{
  background-color: #be97ca;
}

.btn-change-state{
  color: #A23775;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0;
}

.btn-change-state:hover{
  color: #5D1A71;
}

.body-presentation h2 {
  margin-top: 2em;
}

