.cp-form-step-three{
    text-align: center;
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.cp-form-step-three h4{
    text-align: left;
}

.cp-form-step-three h3{
  text-align: left;
  margin-bottom: 1em;
}

.structure-form-container-siret{
    text-align: left;
}

/* Style général pour les champs de formulaire */
.cp-form-step-three input{
  width: 100%;
  padding: 0.5em;
  font-size: 1rem;
  color: #333;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Effet survol pour les champs de saisie */
.cp-form-step-three input:hover {
  border-color: #A23775; /* Couleur violette foncée */
}

/* Effet focus pour indiquer la saisie active */
.cp-form-step-three input:focus {
  outline: none;
  border-color: #5D1A71; /* Couleur violette encore plus foncée */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Label pour améliorer l'alignement et l'espacement */
.cp-form-step-three label {
    margin-top: 1em;
    text-align: left;
  display: block;
  font-size: 1rem;
  margin-bottom: 0.5em;
}

/* Style de l'en-tête de section */
.cp-form-step-three>h4 {
  color: #A23775;
  font-size: 1.4rem;
  margin-top: 2em;
  margin-bottom: 0.5em;
  border-bottom: 2px solid #A23775;
  padding-bottom: 0.3em;
}

.cp-form-step-three p{
  text-align: left;
}

/* Espacement entre les sections du formulaire */
.cp-form-step-three>.body-presentation > div {
  margin-bottom: 1.5em;
}

.suggestions-list {
    cursor: pointer;
    list-style-type: none;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 10%;
    overflow-y: auto

}
    .suggestion-item{
        text-align: left;
    }  

.suggestion-item:hover {
    background-color: #e7e7e7;
}

.step-three-form-section{
    margin-top: 2em;
    cursor: pointer;
    background-color: #fff;
    padding: 1em;
    border-radius: 8px;
    margin-bottom: 2em;
}

.cp-form-step-three{
  margin-top: 2em;
}

.cp-form-step-three h2{
  text-align: left;
}

.cp-form-step-three h3{
  font-size: 1.5rem;
  margin-bottom: auto;
}

.structure-section-address-infos {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 colonnes */
  gap: 1rem; /* Espace entre les champs */
}




