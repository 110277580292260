.cp-expos-form-step-one {
  margin-top: 1em;
  height: 100vh;
    display: flex;
  }
  @media (max-width: 1000px) {
    .cp-expos-form-step-one {
      height: min-content;
      display: block;
    }
    .main-content>img{
      display: none;
    }
  }
  
  .cp-expos-form-step-one .sidebar {
    min-width: 25%;
    padding: 20px;
    border-right: 1px solid #ddd;
  }
  
  .cp-expos-form-step-one .sidebar h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .cp-expos-form-step-one .product-list {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 0; /* Supprime l'espacement intérieur gauche */
    margin: 0;
  }

  .product-list li {
    margin-top: 0.5em;
    list-style: none;
  }
  
  .cp-expos-form-step-one .product-card {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .cp-expos-form-step-one .product-card:hover {
    border-color: #D04C8C ;
  }
  
  .cp-expos-form-step-one .product-card.active {
    border-color: #5D1A71 ;
    background-color: #cc7aa22a;
  }
  
  .cp-expos-form-step-one .product-image {
    width: 100%;
    max-width: 100px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  
  .cp-expos-form-step-one .main-content {
    margin-left: auto;
    margin-right: auto;
    padding: 2em;
    width: 90%;
  }
  
  .cp-expos-form-step-one .product-container {
    background-color: #fff;
    padding: 2em;
    border-radius: 8px;
    box-shadow: 0 0px 8px #5D1A71;
    margin-bottom: 2em;
  }

  .cp-expos-form-step-one .grayed-out {
    color: #ccc; /* Couleur grise pour les jours non valides */
    text-decoration: line-through; /* Optionnel : barrer le texte */
  }
  
  
  .cp-expos-form-step-one .product-container h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .cp-expos-form-step-one .product-details p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .cp-expos-form-step-one .product-detail-image {
    object-fit: cover;
    width: 20%;
    max-width: 200px;
    margin-bottom: 20px;
    float: left;
    margin-right: 2em;

  }

  .description-columns {
    display: inline-block; /* Afficher les listes en ligne */
    vertical-align: top; /* Aligner les listes au sommet */
    width: 48%; /* Largeur de chaque liste (ajuster selon l'espace disponible) */
    margin-right: 2%; /* Espacement entre les colonnes */
  }

  .cp-expos-form-step-one .category-title {
    cursor: pointer;
    user-select: none;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }

  .cp-expos-form-step-one .category-title:hover {
    background-color: #ececec;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .cp-expos-form-step-one .category-title .arrow-icon {
    font-size: 16px;
    transition: transform 0.3s ease;
  }
  
  .cp-expos-form-step-one  .category-title.open .arrow-icon {
    transform: rotate(180deg); /* Rotation de la flèche vers le bas */
  }

  .main-content>img{
    margin-left: 43%;
    margin-bottom: 2em;
  }
  